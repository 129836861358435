import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { UploadGymImageModalComponent } from 'src/app/components/modals/upload-gym-image-modal/upload-gym-image-modal.component';
import { UploadImageModalComponent } from 'src/app/components/modals/upload-image-modal/upload-image-modal.component';
import { ToasterType } from 'src/app/models/toaster.model';
import { GymService } from 'src/app/services/gym.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-general-form',
  templateUrl: './settings-general-form.component.html',
  styleUrls: ['./settings-general-form.component.scss']
})
export class SettingsGeneralFormComponent implements OnInit {
  @Input() gym: any;

  settingsGeneralForm: FormGroup;
  generalTabOpen: boolean = true;
  socialsTabOpen: boolean = true;
  timezones: string[] = [];
  submitted = false;
  imageUrl: string | undefined;

  constructor(private fb: FormBuilder, private gymService: GymService, private toasterService: ToasterService, private dialog: MatDialog, private ref: ChangeDetectorRef) {
    this.settingsGeneralForm = this.fb.group({
      name: ['', Validators.required],
      timezone: ['', Validators.required],
      abn: [''],
      phone: [''],
      website: [''],
      address: [''],
      supportEmail: ['', Validators.email],
      facebook: [''],
      instagram: [''],
      tiktok: [''],
      youtube: [''],
      twitter: [''],
      logoUrl: [null]
    });

    this.settingsGeneralForm.get('name')?.disable();
    let timezones  = moment.tz.zonesForCountry('AU');
    
    this.timezones = timezones.filter((tz: string) => {
      // filter out the random remote locations
      return !["Macquarie", "_", "Lindeman", "Eucla", "Currie"].some((v: string) => tz.includes(v));
    });

  }

  get businessNameInitial() {
    return this.settingsGeneralForm.get('name')!.value.charAt(0).toUpperCase();
  }

  get businessName() {
    return this.settingsGeneralForm.get('name')!.value;
  }

  ngOnInit(): void {
    if (this.gym) {
      this.settingsGeneralForm.patchValue(this.gym);
      if (this.gym.logoUrl) {
        this.imageUrl = this.gym.logoUrl;
      }
    }
  }

  toggleGeneralTab() {
    this.generalTabOpen = !this.generalTabOpen;
  }

  toggleSocialsTab() {
    this.socialsTabOpen = !this.socialsTabOpen;
  }

  onSubmit() {
    this.submitted = true;
    if (this.settingsGeneralForm.valid) {
      this.gymService.updateGym(this.settingsGeneralForm.value).subscribe(() => this.toasterService.addToaster({type: ToasterType.Success, message: 'Settings updated'}));
      // hide the save footer
      this.settingsGeneralForm.markAsPristine();
    }
  }

  uploadImage() {
    const dialogRef = this.dialog.open(UploadGymImageModalComponent, { data: { name: this.businessName }, width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settingsGeneralForm.get('logoUrl')?.setValue(`${environment.blobBaseUrl}/gym/${result}`);
        this.imageUrl = `${environment.blobBaseUrl}/gym/${result}`;
        this.settingsGeneralForm.get('logoUrl')?.markAsDirty();
        this.ref.detectChanges();
      }
    });
  }
}
