import { Component, Input } from '@angular/core';
import { Step } from './stepper.model';

@Component({
  selector: 'fc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent {
  @Input({required: true}) steps: Step[] = [];
}
