import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from './form/input/text-input/text-input.component';
import { StepperComponent } from './stepper/stepper.component';
import { CommonModule } from '@angular/common';
import { ButtonPrimaryComponent } from './button/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './button/button-secondary/button-secondary.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { MatIconModule } from '@angular/material/icon';
import { RichTextEditorComponent } from './form/input/rich-text-editor/rich-text-editor.component';
import { SignaturePadComponent } from './form/input/signature-pad/signature-pad.component';



@NgModule({
  declarations: [
    TextInputComponent,
    StepperComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    RichTextEditorComponent,
    SignaturePadComponent
  ],
  imports: [
    CommonModule,
    NgHeroiconsModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  exports: [
    TextInputComponent,
    StepperComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    RichTextEditorComponent,
    SignaturePadComponent
  ]
})
export class CommonLibModule { }
