import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BlockBlobClient } from '@azure/storage-blob';
import { convertFileToArrayBuffer } from '../lib/convert-file';

@Injectable({
  providedIn: 'root'
})
export class BlobService {
  constructor(private http: HttpClient) { }

  getAvatarSas() {
    return this.http.get<string>(`${environment.baseUrl}/blob/avatar`);
  }

  uploadAvatar(blob: Blob) {
    const formData = new FormData();
    formData.append("avatar", blob);

    return this.http.post(`${environment.baseUrl}/blob/avatar`, formData);
  }

  uploadGymImage(blob: Blob) {
    const formData = new FormData();
    formData.append("image", blob);

    return this.http.post(`${environment.baseUrl}/blob/gym-image`, formData);
  }
}
