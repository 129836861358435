import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageModalComponent } from 'src/app/components/modals/upload-image-modal/upload-image-modal.component';
import { StudentService } from 'src/app/services/student.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToasterType } from 'src/app/models/toaster.model';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-student-create-form',
  templateUrl: './student-create-form.component.html',
  styleUrls: ['./student-create-form.component.scss']
})
export class StudentCreateFormComponent implements OnInit{
  @Input() student: any;
  imageUrl: string | undefined = undefined;

  editing = false;
  submitted = false;
  studentCreateForm = this.fb.group({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    email: new FormControl<string|null>(null, [Validators.email]),
    mobile: new FormControl<string|null>(null, [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)]),
    address: new FormControl<string|null>(null),
    imageUrl: new FormControl<string|null>(null),
    emergencyContact: this.fb.group({
      emergencyFirstName: new FormControl<string|null>(null),
      emergencyLastName: new FormControl<string|null>(null),
      emergencyRelationship: new FormControl<string|null>(null),
      emergencyMobile: new FormControl<string|null>(null, [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)])
    })
  })

  get studentName(): string | null {
    return this.studentCreateForm.get('firstName')?.value ?? null;
  }

  constructor(private fb: FormBuilder, private studentService: StudentService, private dialog: MatDialog, private ref: ChangeDetectorRef, private router: Router, private toasterService: ToasterService) { }

  ngOnInit(): void {
    if (this.student) {
      this.editing = true;
      this.studentCreateForm.setValue({
        firstName: this.student.firstName || '',
        lastName: this.student.lastName || '',
        email: this.student.email|| '',
        mobile: this.student.mobile || '',
        address: this.student.address || '',
        imageUrl: this.student.imageUrl || '',
        emergencyContact: {
          emergencyFirstName: this.student.emergencyFirstName || '',
          emergencyLastName: this.student.emergencyLastName || '',
          emergencyRelationship: this.student.emergencyRelationship || '',
          emergencyMobile: this.student.emergencyMobile || ''
        }
      });
      
      if (this.student.imageUrl) {
        this.imageUrl = this.student.imageUrl;
      }
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.studentCreateForm.valid) {
      if (this.student) {
        this.studentService.updateStudent(this.student.id, this.studentCreateForm.value).subscribe({
          next: () => {
            this.toasterService.addToaster({type: ToasterType.Success, message: 'Student updated'});
            this.studentCreateForm.markAsPristine();
            this.submitted = false;
          },
          error: (error) => {
            const emailControl = this.studentCreateForm.get('email');
            emailControl?.setErrors({ ...(emailControl.errors || {}), 'duplicate': error.error.message});
          }
        })
      } else {
        this.studentService.createStudent(this.studentCreateForm.value).subscribe({
          next: (result) => {
            this.toasterService.addToaster({type: ToasterType.Success, message: 'Student created'});
            this.router.navigate(['student', result]);
          },
          error: (error) => {
            const emailControl = this.studentCreateForm.get('email');
            emailControl?.setErrors({ ...(emailControl.errors || {}), 'duplicate': error.error.message});
          }
        });
      }
    }
  }

  uploadImage() {
    const dialogRef = this.dialog.open(UploadImageModalComponent, { data: { studentName: this.studentName }, width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.studentCreateForm.get('imageUrl')?.setValue(`${environment.blobBaseUrl}/avatar/${result}`);
        this.imageUrl = `${environment.blobBaseUrl}/avatar/${result}`;
        this.studentCreateForm.get('imageUrl')?.markAsDirty();
        this.ref.detectChanges();
      }
    });
  }
}
