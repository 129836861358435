<app-loader *ngIf="!loaded" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
<ng-container *ngIf="loaded">
    <div class="flex items-center justify-between">
        <app-back-button></app-back-button>
        <div class="flex h-[30px] whitespace-nowrap select-none sm:hidden">
            <a [routerLink]="['/session', 'edit', sessionInstanceId]" class="flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">
            <pencil-outline-icon svgClass="flex-inline" [size]="16"></pencil-outline-icon> Edit
            </a>
        </div>
    </div>
    <div class="mt-2 flex flex-col">
        <div class="flex flex-col sm:flex-row justify-between">
            <h1 class="text-3xl font-medium text-gray-900">Session: {{sessionInstance?.title}}</h1>
            <div class="hidden sm:flex h-[38px] whitespace-nowrap select-none">
            <a [routerLink]="['/session', 'edit', sessionInstanceId]" class="flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">
            <pencil-outline-icon svgClass="flex-inline" [size]="16"></pencil-outline-icon> Edit Session
            </a>
            </div>
        </div>
        <h5 class="mt-2 text-gray-500">{{discipline}} - {{sessionStart?.format("dddd Do of MMMM, YYYY")}} &#64; {{sessionStart?.format("h:mm A")}} to {{sessionEnd?.format("h:mm A")}}</h5>
    </div>
    <ng-container *ngIf="!sessionInstance?.isPrivate">
    <div  class="flex flex-col mt-5 gap-1.5 pb-5 border-b w-full">
        <label class="text-sm font-medium text-gray-700">Add student</label>
        <input type="text"  class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[10px] rounded-lg hover:bg-gray-50" placeholder="Search for a student..." matInput [formControl]="autoCompleteControl" [matAutocomplete]="auto"/>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
            <mat-option *ngFor="let student of filteredStudents | async" [value]="student">
                {{student.firstName}} {{student.lastName}}
            </mat-option>
        </mat-autocomplete>
    </div>
    <div *ngIf="selectedStudents.length > 0" class="mt-5 border overflow-y-hidden rounded-lg">
        <table class="w-full overflow-scroll">
            <thead class="bg-gray-50 text-gray-500">
                <tr class="text-left">
                    <th class="pl-6 py-3 w-1/4 font-medium">
                        Name
                    </th>
                    <th class="pl-6 py-3 font-medium">
                        Disciplines
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="[&>*:not(:first-child)]:border-t">
                <tr *ngFor="let student of selectedStudents" class="hover:bg-gray-50">
                    <td class="px-6 py-4 cursor-pointer" (click)="navigateToStudent(student.id)">
                        <div class="flex items-center gap-3">
                            <div>
                                <app-avatar [imageUrl]="student.imageUrl" [initials]="student.firstName.charAt(0).toUpperCase() + student.lastName.charAt(0).toUpperCase()"></app-avatar>
                            </div>
                            <div class="flex flex-col">
                                <span class="font-medium">{{student.firstName}} {{student.lastName}}  <span *ngIf="!student.isActive">(Archived)</span></span>
                                <span class="text-gray-500">{{student.email}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <div class="flex flex-col justify-center">
                            <div>
                                <ng-container *ngIf="student.disciplines.length > 0">
                                    <span *ngFor="let discipline of student.disciplines" class="whitespace-nowrap grow-0 px-2 py-1 text-gray-700 font-medium text-xs rounded-2xl bg-gray-50">
                                        {{discipline.discipline}}: {{discipline.grade}}
                                    </span>
                                </ng-container>
                                <p class="font-medium text-gray-700 text-sm" *ngIf="student.disciplines.length == 0">No disciplines</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="flex justify-end">
                        <trash-outline-icon (click)="removeStudent(student.id)" svgClass="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </ng-container>
    <ng-container *ngIf="sessionInstance?.isPrivate">
        <div class="mt-8">
            <div class="text-gray-900 text-lg font-medium">Students</div>
        </div>
        <div class="mt-5 border overflow-y-hidden rounded-lg">
        <table class="w-full" *ngIf="selectedStudents.length > 0">
            <thead class="bg-gray-50 text-xs text-gray-500 border-b">
                <tr class="text-left">
                    <th class="pl-6 py-3 w-1/4 font-medium">
                        Name
                    </th>
                    <th class="pl-6 py-3 w-1/6 font-medium">
                        Disciplines
                    </th>
                    <th class="pl-6 py-3 w-[40%] font-medium">
                        Rank progress
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="[&>*:not(:first-child)]:border-t">
                <tr *ngFor="let student of selectedStudents" [ngClass]="{'hover:bg-gray-50': isCheckedIn(student.id), 'bg-error-50 hover:bg-error-100': !isCheckedIn(student.id)}">
                    <td class="px-6 py-4 cursor-pointer" (click)="navigateToStudent(student.id)">
                        <div class="flex items-center gap-3">
                            <div>
                                <app-avatar [imageUrl]="student.imageUrl" [initials]="student.firstName.charAt(0).toUpperCase() + student.lastName.charAt(0).toUpperCase()"></app-avatar>
                            </div>
                            <div class="flex flex-col text-sm">
                                <span class="font-medium">{{student.firstName}} {{student.lastName}} <span *ngIf="!student.isActive">(Archived)</span></span>
                                <span class="text-gray-500">{{student.email}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-3">
                        <div class="flex flex-col justify-center">
                            <div class="flex flex-wrap">
                                <ng-container *ngIf="student.disciplines.length > 0">
                                    <span *ngFor="let discipline of student.disciplines" class="whitespace-nowrap grow-0 px-2 py-1 text-gray-700 font-medium text-xs rounded-2xl bg-gray-50">
                                        {{discipline.discipline}}: {{discipline.grade}}
                                    </span>
                                </ng-container>
                                <p class="font-medium text-gray-700 text-sm" *ngIf="student.disciplines.length == 0">No disciplines</p>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <div *ngIf="student.disciplines.length > 0" class="flex justify-center items-center gap-3">
                            <div class="grow bg-gray-100 rounded-full h-2 flex">
                                <div class="bg-primary-600 h-2 rounded-full" [ngStyle]="{'width': getProgress(student.id) + '%'}"></div>
                            </div>
                            <span class="font-medium text-gray-700 text-sm">{{ getProgress(student.id) }}%</span>
                        </div>
                        <p class="font-medium text-gray-700 text-sm" *ngIf="student.disciplines.length == 0">No rank</p>
                    </td>
                    <td>
                        <div class="px-6 py-4 flex justify-end text-sm text-gray-500 font-semibold mr-4">
                        <span (click)="isCheckedIn(student.id) ? removeStudent(student.id) : checkIn(student.id)" class="cursor-pointer">
                            {{ isCheckedIn(student.id) ? 'Cancel session' : 'Remove cancellation' }}
                        </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mt-8 flex justify-between">
        <div>
            <div class="text-gray-900 text-lg font-medium">{{selectedStudents.length == 1 ? selectedStudents[0].firstName + "'s notes" :  'Student notes'}}</div>
        </div>
        <div>
            <button (click)="addNote()"  class="h-[38px] whitespace-nowrap select-none flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2">
            <pencil-outline-icon svgClass="flex-inline" [size]="16"></pencil-outline-icon> Add note
            </button>
        </div>
        </div>
        <div class="mt-5 border overflow-hidden rounded-lg" *ngIf="studentNotes && studentNotes.length > 0">
        <table class="w-full">
            <tbody class="[&>*:not(:first-child)]:border-t">
                <tr class="hover:bg-gray-50" *ngFor="let note of currentPageNotes">
                    <td *ngIf="selectedStudents.length > 1" class="px-6 py-4 w-auto align-top cursor-pointer">
                        <div class="flex items-center gap-3">
                            <div>
                                <app-avatar [imageUrl]="note.imageUrl" [initials]="note.studentFirstName.charAt(0).toUpperCase() + note.studentLastName.charAt(0).toUpperCase()"></app-avatar>
                            </div>
                            <div class="flex flex-col">
                                <span class="text-sm font-medium whitespace-nowrap">{{note.studentFirstName}} {{note.studentLastName.charAt(0)}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="px-6 py-4 w-full align-top">
                        <div *ngIf="note.sessionInstanceId" class="text-sm font-medium text-gray-900">
                            {{note.isPrivate ? 'Private session' : 'General session'}}
                            {{!note.sessionInstanceIsActive ? ' (Cancelled)' : ''}}
                        </div>
                        <div *ngIf="!note.sessionInstanceId" class="text-sm font-medium text-gray-900">
                            General note
                        </div>
                        <div class="text-sm text-gray-500">
                            <ng-container *ngFor="let line of note.content.split('\n'); let i = index">
                                <p [class]="i === 0 ? '':'mt-1'">{{ line }}</p>
                            </ng-container>
                        </div>
                    </td>
                    <td class="px-6 py-4 w-auto align-top">
                        <div class="flex flex-col">
                            <span class="text-sm font-medium text-gray-900 whitespace-nowrap">
                                {{getNoteDate(note.date)}}
                            </span>
                            <span class="text-sm text-gray-500 whitespace-nowrap">
                                {{getNoteDateElapsed(note.date)}}
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                <td class="px-6 py-4">
                    <span>Page {{currentPage}} of {{lastPage}}</span>
                </td>
                <td class="px-4 py-4" colSpan="2">
                    <div *ngIf="studentNotes.length > pageSize"  class="flex justify-end text-sm text-gray-500 font-medium">
                    <button (click)="back()" [disabled]="!canGoBack()"  class="flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center mr-2 disabled:bg-gray-100">
                        Previous
                    </button>
                    <button (click)="next()" [disabled]="!canGoNext()" class="flex items-center gap-1 text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center disabled:bg-gray-100">
                        Next
                    </button>
                    </div>
                </td>
                </tr>
            </tbody>
        </table>
    </div>
    </ng-container>
</ng-container>