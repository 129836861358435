import { Output, EventEmitter, Component, OnDestroy, OnInit, forwardRef, ViewChild, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill'

@Component({
  selector: 'fc-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorComponent),
      multi: true
    }
  ]
})
export class RichTextEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('editorContainer', { static: true }) editorContainer!: ElementRef;

  @Input() placeholderText: string = 'Enter document details...'
  @Output() initialValueSet: EventEmitter<void> = new EventEmitter<void>();

  private editor: Quill | undefined;
  private onChangeCallback: (value: string) => void | undefined = () => {};
  private onTouchedCallback: () => void | undefined = () => {};

  private initialValue = true;

  ngOnInit(): void {
    this.editor = this.configureQuill()
  }

  ngOnDestroy(): void {
    if (this.editor && this.editor.off) {
      this.editor.off('text-change', this.textChangeHandler);
    }
  }

  writeValue(value: string): void {
    if (this.editor) {
      if (value === '') {
        this.initialValue = false;
      }
      this.editor.root.innerHTML = value;
    }
  }

  registerOnChange(callback: (value: string) => void): void {
    this.onChangeCallback = callback;
  }

  registerOnTouched(callback: () => void): void {
    this.onTouchedCallback = callback;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.editor){
      this.editor.enable(!isDisabled);
    }
  }

  private textChangeHandler = () => {
    if (this.editor) {
      this.onChangeCallback(this.editor.root.innerHTML);
      this.onTouchedCallback();
    }
    if (this.initialValue) {
      this.initialValueSet.emit();
      this.initialValue = false;
    }
  }

  configureQuill(): Quill {
    const toolbarOptions = [
      ['bold', 'italic'],
      [{'header': 1}, {'header': 2}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['clean']
    ];

    const quill = new Quill(this.editorContainer.nativeElement, {
      modules: {
        toolbar: toolbarOptions,
      },
      placeholder: this.placeholderText,
      theme: 'snow'
    });

    // Listen for text-change events to update the form control
    quill.on('text-change', this.textChangeHandler);

    return quill;
  }
}

