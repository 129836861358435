<form [formGroup]="studentCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-8 mb-20">
        <div class="flex flex-col sm:flex-row pb-5 border-b border-gray-200">
            <div class="mr:0 sm:mr-[130px]">
                <div class="flex-1">
                    <label class="block mb-2 text-sm font-medium text-gray-700">First Name</label>
                    <input type="text" formControlName="firstName"
                        [ngClass]="{'w-full sm:w-[350px] border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('firstName')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('firstName')?.valid ?? true}"
                        placeholder="Required..." />
                    <span *ngIf="submitted && !studentCreateForm.get('firstName')?.valid ?? false"  class="block mt-2 text-error-500">First name is required</span>
                </div>
                <div class="mt-5">
                    <div class="flex-1">
                        <label class="block mb-2 text-sm font-medium text-gray-700">Last Name</label>
                        <input type="text" formControlName="lastName"
                            [ngClass]="{'w-full sm:w-[350px] border focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('lastName')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('lastName')?.valid ?? true}"
                            placeholder="Required..." />
                        <span *ngIf="submitted && !studentCreateForm.get('lastName')?.valid ?? false"  class="block mt-2 text-error-500">Last name is required</span>
                    </div>
                </div>
            </div>
            <div class="order-first sm:order-last w-full flex justify-center sm:justify-start">
                <div (click)="uploadImage()"
                    class="cursor-pointer inline-flex items-center justify-center w-[160px] h-[160px] overflow-hidden bg-primary-50 hover:bg-primary-200 rounded-full">
                    <img *ngIf="imageUrl" [src]="imageUrl" />
                    <user-outline-icon *ngIf="!imageUrl" class="p-10" svgClass="stroke-1 stroke-primary-600" [size]="80"></user-outline-icon>
                </div>
            </div>
        </div>
        <div class="mt-5 pb-5 border-b border-gray-200">
            <div class="max-w-[500px]">
                <fc-text-input label="Email" formControlName="email" [submitted]="submitted" [errorMessages]="{'required':'Please enter a valid email'}">
                    <envelope-outline-icon #icon></envelope-outline-icon>
                </fc-text-input>
            </div>
            <div class="mt-5">
                <div class="flex-1 max-w-[500px]">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Mobile</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <device-phone-mobile-outline-icon
                                svgClass="w-5 h-5 text-gray-500 dark:text-gray-400"></device-phone-mobile-outline-icon>
                        </div>
                        <input type="text" formControlName="mobile"
                            [ngClass]="{'w-full border focus:outline-0 focus:ring text-sm px-10 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('mobile')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('mobile')?.valid ?? true}"
                            />
                    </div>
                    <span *ngIf="submitted && !studentCreateForm.get('mobile')?.valid ?? false"  class="block mt-2 text-error-500">Please enter a valid mobile</span>
                </div>
            </div>
        </div>
        <div formGroupName="emergencyContact" class="mt-5 pb-5">
            <div class="flex flex-col sm:flex-row justify-start gap-5">
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact First Name</label>
                    <input type="text" formControlName="emergencyFirstName"
                        class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg hover:bg-gray-50" />
                </div>
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact Last Name</label>
                    <input type="text" formControlName="emergencyLastName"
                        class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg hover:bg-gray-50" />
                </div>
            </div>
            <div class="mt-5 flex flex-col sm:flex-row justify-start gap-5">
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact Relationship</label>
                    <input type="text" formControlName="emergencyRelationship"
                        class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg hover:bg-gray-50" />
                </div>
                <div class="w-full sm:max-w-[350px] sm:w-1/2">
                    <label class="block mb-2 text-sm font-medium text-gray-700">Emergency Contact Mobile</label>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <device-phone-mobile-outline-icon
                                svgClass="w-5 h-5 text-gray-500 dark:text-gray-400"></device-phone-mobile-outline-icon>
                        </div>
                        <input type="text" formControlName="emergencyMobile"
                            [ngClass]="{'w-full border focus:outline-0 focus:ring text-sm px-10 py-3 rounded-lg hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !studentCreateForm.get('emergencyContact')?.get('emergencyMobile')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || studentCreateForm.get('emergencyContact')?.get('emergencyMobile')?.valid ?? true}"
                            />
                    </div>
                    <span *ngIf="submitted && !studentCreateForm.get('emergencyContact')?.get('emergencyMobile')?.valid ?? false"  class="block mt-2 text-error-500">Please enter a valid mobile</span>
                </div>
            </div>
        </div>
        <app-save-footer *ngIf="this.studentCreateForm.dirty" [buttonText]="editing ? 'Update student' : 'Create student'" [error]="submitted && !studentCreateForm.valid"></app-save-footer>
    </div>
</form>