<form [formGroup]="settingsGeneralForm" (ngSubmit)="onSubmit()" class="mb-16">
    <!-- General -->
    <div class="flex flex-col gap-4 m-6">
        <div class="flex flex-col gap-2 pb-5  border-b">
            <div class="font-semibold text-gray-900 text-lg">Business details</div>
            <div class="text-gray-600 text-sm">These details are shared with your students while they interact with your business.</div>
        </div>
        <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
            <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">
                Business name
            </span>
            <div class="flex grow">
                <input type="text" formControlName="name"
                    class="disabled:text-gray-700 border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full bg-gray-50" />
                <question-mark-circle-outline-icon [size]="22" matTooltip="Contact FightCloud to update" matTooltipPosition="above"
                    class="text-gray-400 -ml-8 mt-3">
                </question-mark-circle-outline-icon>
            </div>
        </div>
        <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
            <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">
                Time zone
            </span>
            <div class="grow">
                <select formControlName="timezone"
                    class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50 basis-1 sm:basis-[512px]">
                    <option *ngFor="let tz of timezones" [value]="tz">{{tz}}</option>
                </select>
            </div>
        </div>
        <div class="flex flex-col sm:flex-row gap-2 pb-5 border-b">
            <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">
                Your logo
            </span>
            <div class="grow">
                <div (click)="uploadImage()"
                class="cursor-pointer inline-flex items-center justify-center w-[160px] h-[160px] overflow-hidden bg-primary-50 hover:bg-primary-200 rounded-full">
                <img *ngIf="imageUrl" [src]="imageUrl" />
                <span class="text-5xl" *ngIf="!imageUrl">{{businessNameInitial}}</span>
                </div>
            </div>
        </div>
        <div class="flex flex-col sm:flex-row gap-2">
            <span class="font-medium text-gray-700 text-sm basis-1 sm:basis-[280px]">
                Support email
            </span>
            <div class="grow">
                <fc-text-input formControlName="supportEmail" [submitted]="submitted" placeholder="Support email"></fc-text-input>
            </div>
        </div>
    </div>
    <ng-container *ngIf="settingsGeneralForm.dirty">
        <app-save-footer buttonText="Save" [error]="submitted && !settingsGeneralForm.valid"></app-save-footer>
    </ng-container>
</form>
