import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentService } from 'src/app/services/document.service';
import { Document } from 'src/app/models/document.model';
import { MatDialog } from '@angular/material/dialog';
import { UploadGymImageModalComponent } from '../../../../components/modals/upload-gym-image-modal/upload-gym-image-modal.component'
import { environment } from 'src/environments/environment';
import { GymService } from 'src/app/services/gym.service';

@Component({
  selector: 'app-settings-apps-form',
  templateUrl: './settings-apps-form.component.html',
  styleUrl: './settings-apps-form.component.scss'
})
export class SettingsAppsFormComponent implements OnInit {
  settingsAppsForm: FormGroup;
  submitted = false;
  documents: Document[] = [];

  constructor(private fb: FormBuilder, private documentService: DocumentService, private gymService: GymService, private dialog: MatDialog, private ref: ChangeDetectorRef) {
    this.settingsAppsForm = this.fb.group({
      waiverDocumentId: this.fb.control<number | null>(null, [Validators.required]),
      getStartedImageUrl: this.fb.control<string | null>(null),
      studentDetailsImageUrl: this.fb.control<string | null>(null),
      emergencyContactImageUrl: this.fb.control<string | null>(null),
    });
  }

  get getStartedImageUrl() {
    return this.settingsAppsForm?.get('getStartedImageUrl')?.value as string;
  }

  get studentDetailsImageUrl() {
    return this.settingsAppsForm?.get('studentDetailsImageUrl')?.value as string;
  }

  get emergencyContactImageUrl() {
    return this.settingsAppsForm?.get('emergencyContactImageUrl')?.value as string;
  }

  ngOnInit(): void {
    this.documentService.getDocuments().subscribe(documents => {
      console.log(documents);
      documents
        .filter(x => !x.isArchived)
        .forEach(document => {
          const currentVersion = document.versions[0];
          if (currentVersion.signatureRequired) {
            this.documents.push(document);
          }
        });
    });

    this.gymService.getGymAppSettings().subscribe(gymAppSettings => {
      this.settingsAppsForm.patchValue(gymAppSettings);
    })
  }

  onSubmit() {
    if (this.settingsAppsForm.valid) {
      this.gymService.updateGymAppSettings(this.settingsAppsForm.value).subscribe();
    }
  }

  uploadImage(formControlName: string) {
    const dialogRef = this.dialog.open(UploadGymImageModalComponent, { data: { studentName: '', aspectRatio: 2/3 }, width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const imageUrl = `${environment.blobBaseUrl}/gym/${result}`;
        this.settingsAppsForm.get(formControlName)?.setValue(imageUrl);
        this.settingsAppsForm.markAsDirty();
        this.ref.detectChanges();
      }
    });
  }
}
