import { Component, OnInit } from '@angular/core';
import { GymService } from 'src/app/services/gym.service';

@Component({
  selector: 'app-settings-apps',
  templateUrl: './settings-apps.component.html',
  styleUrl: './settings-apps.component.scss'
})
export class SettingsAppsComponent implements OnInit {
  loaded = false;
  constructor(private gymService: GymService) {}

  ngOnInit(): void {
    this.gymService.getGym().subscribe(response => {
      // this.gym = response;
      this.loaded = true;
    })
  }
}
